import NavBarDesktop from '@/components/NavBarDesktop';
import NavBarMobile from '@/components/NavBarMobile';
import NavBarToggleButton from '@/components/NavBarToggleButton';
import { MEDIA_URL } from '@/constants/env';
import { fadeInDown } from '@/constants/motions';
import { NavBarProvider } from '@/context/navbar';
import { useOnWindowScroll } from '@/hooks/useOnWindowScroll';
import Image from '@/ui/Image';
import cx from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';

export default function HeaderBlock() {
  const { scrolled } = useOnWindowScroll();

  return (
    <NavBarProvider>
      <motion.header
        className={cx('fixed top-0 z-40 h-24 w-full bg-white px-4', {
          'bg-opacity-80 shadow-lg backdrop-blur-md': scrolled,
        })}
        initial="hidden"
        transition={fadeInDown.transition}
        variants={fadeInDown}
        viewport={fadeInDown.viewport}
        whileInView="visible"
      >
        <div className="container-screen h-full">
          <div className="flex h-full items-center justify-between py-2">
            <Link href="/">
              <a>
                <Image
                  alt="logo"
                  className="h-20 w-24 object-contain"
                  src={MEDIA_URL.concat('/logo.png')}
                />
              </a>
            </Link>
            <NavBarDesktop />
            <NavBarToggleButton />
          </div>
        </div>
      </motion.header>
      <NavBarMobile />
    </NavBarProvider>
  );
}

import { primaryMenu } from '@/content';
import { useNavBar } from '@/hooks/navbar';
import Button from '@/ui/Button';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRef } from 'react';

const staggerChildUp = {
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
};

export default function NavBarMobile() {
  const { isOpen, setOpen } = useNavBar();

  // Close nav when clicking outside
  const navRef = useRef();
  // useOutsideClick(navRef, () => setOpen(false));

  return (
    <motion.nav
      ref={navRef}
      animate={isOpen ? 'open' : 'closed'}
      className="fixed right-4 top-20 z-50 w-full max-w-[240px] overflow-hidden rounded-xl bg-white py-6 px-8 shadow-lg ring-1 ring-neutral-200"
      initial={false}
      tabIndex="-1"
      variants={{
        closed: {
          opacity: 0,
          y: 12,
          transition: {
            ease: 'easeIn',
            staggerChildren: 0.05,
            staggerDirection: -1,
          },
        },
        open: {
          opacity: 1,
          y: 0,
          transition: {
            ease: 'easeOut',
            staggerChildren: 0.07,
            delayChildren: 0.1,
          },
        },
      }}
    >
      <div className=" flex flex-col gap-2">
        {primaryMenu.map(({ text, url, icon }, i) => (
          <Link key={i} passHref href={url}>
            <motion.a
              className="relative flex cursor-pointer items-center gap-4 rounded-md py-2 px-4 text-lg font-semibold transition duration-300 hover:bg-primary-400/20 hover:text-primary-400 focus-visible:bg-primary-400/20 focus-visible:text-primary-400"
              tabIndex="-1"
              variants={staggerChildUp}
            >
              <Icon className="opacity-60" height="16" icon={icon} />
              {text}
            </motion.a>
          </Link>
        ))}
      </div>
      <Link passHref href="/#contact">
        <Button
          className="mt-4 w-full"
          color="primary"
          component={motion.a}
          variants={staggerChildUp}
        >
          <Icon height="16" icon="fluent:call-16-filled" />
          Contact Us
        </Button>
      </Link>
    </motion.nav>
  );
}

import FooterBlock from '@/blocks/FooterBlock';
import HeaderBlock from '@/blocks/HeaderBlock';
import { SITE_TITLE } from '@/constants/env';
import Head from 'next/head';

export default function FullWidthLayout({ children, title }) {
  return (
    <>
      <Head>
        <title>
          {title && `${title} - `}
          {SITE_TITLE}
        </title>
      </Head>
      <HeaderBlock />
      <main className="mt-24">{children}</main>
      <FooterBlock />
    </>
  );
}

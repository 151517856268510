import { primaryMenu } from '@/content';
import Button from '@/ui/Button';
import { Icon } from '@iconify/react';
import Link from 'next/link';

export default function NavBarDesktop() {
  return (
    <div className="hidden md:flex md:gap-8">
      <nav className="flex items-center gap-4">
        {primaryMenu.map(({ text, url }, i) => (
          <Link key={i} href={url}>
            <a className="relative py-2 px-4 text-lg font-semibold transition duration-300 after:absolute after:bottom-0 after:left-1/2 after:h-[4px] after:w-8 after:-translate-x-1/2 after:translate-y-2 after:rounded-full after:bg-primary-400 after:opacity-0 after:transition-transform after:duration-500 after:ease-out after:content-[''] hover:text-primary-400 hover:after:translate-y-0 hover:after:opacity-100">
              {text}
            </a>
          </Link>
        ))}
      </nav>
      <Link passHref href="/#contact">
        <Button color="primary" component="a">
          <Icon height="16" icon="fluent:call-16-filled" />
          Contact Us
        </Button>
      </Link>
    </div>
  );
}

import { useNavBar } from '@/hooks/navbar';
import dismissFilled from '@iconify/icons-fluent/dismiss-24-filled';
import listFilled from '@iconify/icons-fluent/list-24-filled';
import { Icon } from '@iconify/react/dist/offline';
import { motion } from 'framer-motion';

export default function NavBarToggleButton() {
  const { isOpen, toggleOpen } = useNavBar();

  return (
    <button
      className="flex h-12 w-12 items-center justify-center rounded-md text-neutral-600 transition hover:bg-primary-500/20 hover:text-primary-700 focus:outline-none focus-visible:bg-primary-500/30 focus-visible:text-primary-700 md:hidden"
      onClick={toggleOpen}
    >
      <motion.span layout transition={{ duration: 0.3 }}>
        {isOpen ? (
          <Icon height="28" icon={dismissFilled} />
        ) : (
          <Icon height="32" icon={listFilled} />
        )}
      </motion.span>
    </button>
  );
}

import { MEDIA_URL, SITE_TITLE } from '@/constants/env';
import Image from '@/ui/Image';
import { Icon } from '@iconify/react';
import Link from 'next/link';

export default function FooterBlock() {
  return (
    <footer>
      <FooterMiddle />
      <FooterBottom />
    </footer>
  );
}

function FooterMiddle() {
  const links = [
    {
      href: '/',
      text: 'About',
    },
    {
      href: '/',
      text: 'Blogs',
    },
    {
      href: '/',
      text: 'Contact Us',
    },
  ];
  return (
    <section className="bg-neutral-800 py-8 px-4 md:py-12">
      <div className="container-screen ">
        <div className="grid gap-8 md:grid-cols-1 lg:grid-cols-2 lg:gap-12 xl:grid-cols-4">
          <div className="flex flex-col items-start">
            <Image
              className="h-28 w-28 object-contain md:h-32 md:w-32"
              src={MEDIA_URL.concat('/logo.png')}
            />
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-bold text-white">Quick Links</h3>
            {links.map((item, i) => (
              <div key={i} className="flex flex-col gap-2 md:gap-4">
                <Link href={item.href}>
                  <a className="text-neutral-300 ">{item.text}</a>
                </Link>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-xl font-bold text-white">Reach Us</h3>
            <div className="flex flex-col gap-2 md:gap-2">
              <div className="flex items-start gap-1">
                <Icon
                  className="flex-shrink-0 text-white"
                  height="20"
                  icon="fluent:call-16-filled"
                />
                <p className="leading-snug text-neutral-300">
                  +597520112 | +597520488 | +597426988
                </p>
              </div>
              <div className="flex items-start gap-1">
                <Icon
                  className="flex-shrink-0 text-white"
                  height="20"
                  icon="ant-design:mobile-filled"
                />
                <p className="leading-snug text-neutral-300">+5978229898 | +5978839591</p>
              </div>
              <div className="flex items-center gap-2">
                <Icon
                  className="flex-shrink-0 text-white"
                  height="20"
                  icon="fluent:mail-16-filled"
                />
                <p className="text-neutral-300">info@surinamenaturalstoneco.com</p>
              </div>
              <div className="flex items-start gap-2">
                <Icon
                  className="flex-shrink-0 text-white"
                  height="20"
                  icon="fluent:globe-location-20-filled"
                />
                <p className="leading-snug text-neutral-300">
                  Prins Hendrikstraat 50-52 Paramaribo, Suriname
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 md:gap-4">
            <div className="text-xl font-bold text-white">Social Media</div>
            <div className="flex items-start gap-2">
              <Icon className="text-white" height="20" icon="cib:facebook" />
              <span className="text-neutral-300">Suriname Natural Stone Co NV</span>
            </div>
            <div className="flex items-start gap-2">
              <Icon className="text-white" height="20" icon="cib:linkedin" />
              <span className="text-neutral-300">Suriname Natural Stone Co NV</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function FooterBottom() {
  return (
    <section className="bg-neutral-900 px-4">
      <div className="container-screen bg-neutral-900 py-4">
        <div className="flex items-center justify-between">
          <p className="text-white">
            Copyright &copy; {new Date().getFullYear()} {SITE_TITLE} All rights reserved.
          </p>
          <div className="flex gap-2">
            <Icon className="text-white" height="20" icon="cib:facebook" />
            <Icon className="text-white" height="20" icon="cib:linkedin" />
          </div>
        </div>
      </div>
    </section>
  );
}

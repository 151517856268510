export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  transition: {
    duration: 0.6,
    ease: 'easeOut',
  },
  viewport: {
    once: true,
    margin: '-50px',
  },
};

export const fadeInUp = {
  hidden: {
    opacity: 0,
    translateY: 20,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    duration: 0.6,
    ease: 'easeOut',
  },
  viewport: {
    once: true,
    margin: '-50px',
  },
};

export const fadeInDown = {
  hidden: {
    opacity: 0,
    translateY: -20,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    duration: 0.6,
    ease: 'easeOut',
  },
  viewport: {
    once: true,
  },
};

export const slideInLeft = {
  hidden: {
    opacity: 0,
    translateX: -20,
  },
  visible: {
    opacity: 1,
    translateX: 0,
  },
  transition: {
    duration: 0.6,
    ease: 'easeOut',
  },
  viewport: {
    once: true,
    margin: '-50px',
  },
};

export const slideInRight = {
  hidden: {
    opacity: 0,
    translateX: 20,
  },
  visible: {
    opacity: 1,
    translateX: 0,
  },
  transition: {
    duration: 0.6,
    ease: 'easeOut',
  },
  viewport: {
    once: true,
    margin: '-50px',
  },
};

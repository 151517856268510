import cx from 'classnames';
import { forwardRef } from 'react';

function Button(
  { children, className, component: Component = 'button', color, icon, size, ...props },
  ref
) {
  const colors = {
    primary: 'bg-primary-400 text-white hover:bg-primary-600 focus:ring-2 focus:ring-primary-200',
  }[color];

  const sizes = {
    [undefined]: 'px-4 py-2',
    xs: 'text-xs px-4 py-2',
  }[size];

  return (
    <Component
      ref={ref}
      className={cx(
        'inline-flex items-center justify-center gap-2 rounded-lg border border-transparent text-center transition focus:outline-none',
        colors,
        sizes,
        className
      )}
      {...props}
    >
      {children}
      {icon && icon}
    </Component>
  );
}

export default forwardRef(Button);

import { pages } from './pages';

export const primaryMenu = [
  {
    ...pages.home,
    icon: 'fluent:home-16-filled',
  },
  {
    ...pages.about,
    icon: 'fluent:info-16-filled',
  },
  {
    ...pages.blog,
    icon: 'fluent:news-16-filled',
  },
];

export const pages = {
  home: {
    id: 'home',
    text: 'Home',
    url: '/#home',
  },
  about: {
    id: 'about',
    text: 'About',
    url: '/#about',
  },
  blog: {
    id: 'blog',
    text: 'Blog',
    url: '/#blog',
  },
};

import cx from 'classnames';
import NextImage from 'next/image';

export default function Image({ className, ...props }) {
  let objectFit = 'none';
  if (className?.includes('object-cover')) objectFit = 'cover';
  if (className?.includes('object-contain')) objectFit = 'contain';
  if (className?.includes('object-fill')) objectFit = 'fill';

  return (
    <div className={cx('relative', className)}>
      <NextImage layout="fill" objectFit={objectFit} {...props} />
    </div>
  );
}

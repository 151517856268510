import { useState } from 'react';
import { useOnWindowScroll as useOnWindowScrollOriginal } from 'rooks';

export const useOnWindowScroll = () => {
  const useOnWindowScroll = typeof window === 'object' ? useOnWindowScrollOriginal : () => {};
  const [scrolled, setScrolled] = useState(false);
  useOnWindowScroll(() => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });

  return { scrolled };
};

import { createContext, useState } from 'react';

export const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);

  return (
    <NavBarContext.Provider value={{ isOpen, setOpen, toggleOpen }}>
      {children}
    </NavBarContext.Provider>
  );
};
